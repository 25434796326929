import React from 'react'

import HomeCarousel from '../components/HomeCarousel';
import Conoceme from '../components/Conoceme';

const Home = () => {
    return (
        <div>
            <h1 className="principalHeader">Gonzalo Chacón Larraín</h1>
            <HomeCarousel />
            <Conoceme />
        </div>
    )
}

export default Home;