import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './views/Home';
import NotFound from './views/NotFound';

import SocialMediaLinks from './components/SocialMediaLinks';
import Footer from './components/Footer';
import JsmiFooter from './components/JsmiFooter';

function App() {

  const BaseURL = "/";

  return (
    <div className="App">
      <BrowserRouter>
        <SocialMediaLinks />
        <Routes>
          <Route exact path={BaseURL} element={<Home />} />
          <Route exact path='*' element={<NotFound />} />
        </Routes>
        <Footer />
        <JsmiFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
