import React from 'react'

const Conoceme = () => {
  return (
    <div>
        <div className="col-md-6 col-12 conocemeTexto">
            <h2 className="subtitle">¿QUIÉN ES GONZALO CHACÓN LARRAÍN?</h2>
            <p>Gonzalo Chacón Larraín, tiene una destacada trayectoria en el servicio público, habiendo ejercido roles como intendente, gobernador y Seremi. Su amplia experiencia en la administración pública lo ha preparado para enfrentar los desafíos de la comuna. Ahora, como candidato a concejal, se compromete a combatir la inseguridad, implementando medidas efectivas para proteger a los vecinos y mejorar la calidad de vida en Vitacura.</p>
        </div>
        <div className="col-md-6 col-12">

        </div>
    </div>
  )
}

export default Conoceme;